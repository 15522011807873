import { mapActions, mapState } from 'vuex'
import SCard from '../../../components/ui/s-card'
import SLink from '../../../components/ui/s-link'

export default {
  name: 'MyMatchReports',

  components: {
    SCard,
    SLink,
    MatchReportCard: () => import('../../../components/ReportCard'),
  },

  data() {
    return {
      records: [],
      fullyLoaded: false,
    }
  },

  async created() {
    const { reports } = await this.getPendingReports()
    reports.map((report) => this.records.push(report))
  },

  computed: {
    ...mapState(['loading', 'error']),

    isEmptyList() {
      const hasMatchReports = !this.records.length

      return hasMatchReports && !this.loading
    },
  },

  methods: {
    ...mapActions(['getPendingReports']),

    reload: () => window.location.reload(),
  },
}
